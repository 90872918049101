<template>
  <div class="content-area board-page noselect">
    <hero-bar>
      {{ $t('board') }}
      <b-tooltip slot="right" :label="$t('refresh')" type="is-dark" position="is-bottom">
        <b-button
          id="refresh-button"
          class="mr-10"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': refreshLoading }"
          icon-left="refresh"
          @click="refresh"
        />
      </b-tooltip>
    </hero-bar>

    <section class="section board-items is-main-section level">
      <card-component :has-button-slot="true" :title="$t('availableCars')" icon="car-multiple" class="is-block is-child level-item mr-10">
        <b-tag slot="button" class="count" type="available" rounded>{{ getCount('available_cars') }}</b-tag>
        <b-skeleton :active="pageLoading" :count="30" />
        <car-list :items="board.available_cars" />
      </card-component>

      <card-component :has-button-slot="true" :title="$t('availableDrivers')" icon="account-group" class="is-block is-child level-item mr-10">
        <b-tag slot="button" class="count" type="available" rounded>{{ getCount('available_drivers') }}</b-tag>
        <b-skeleton :active="pageLoading" :count="30" />
        <driver-list :items="board.available_drivers" />
      </card-component>

      <card-component :has-button-slot="true" :title="$t('plannedCars')" icon="car-key" class="is-block is-child level-item mr-10">
        <b-tag slot="button" class="count" type="wdeparture" rounded>{{ getCount('planed_cars') }}</b-tag>
        <b-skeleton :active="pageLoading" :count="30" />
        <car-driver-list :items="board.planed_cars" />
      </card-component>

      <card-component :has-button-slot="true" :title="$t('enrouteCars')" icon="road-variant" class="is-block is-child level-item mr-10">
        <b-tag slot="button" class="count" type="enroute" rounded>{{ getCount('on_road') }}</b-tag>
        <b-skeleton :active="pageLoading" :count="30" />
        <car-driver-list :items="board.on_road" />
      </card-component>

      <card-component :has-button-slot="true" :title="$t('completions')" icon="marker-check" class="is-block is-child level-item complate-transfers">
        <b-tag slot="button" class="count" type="completed" rounded>{{ getCount('complate') }}</b-tag>
        <b-skeleton :active="pageLoading" :count="30" />
        <car-driver-list :items="board.complate" :isComplate="true" />
      </card-component>
    </section>
    <b-modal
      :active.sync="isTransferListModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card full-width" style="width: auto">
        <section class="modal-card-body full-width">
          <transfers-table
            id="transferTable"
            ref="transferTable"
            data-url="/list-transfers-fast"
            :isModal="true"
            :where="transferListWhere"
            :driverId="driverId"
            :carId="carId"
          />
        </section>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isTransferModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card full-width" style="width: auto">
        <section class="modal-card-body full-width">
          <transfer-details
            :id="transferId"
            :isModal="true"
          />
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'Board',
  title: ({ $i18n }) => $i18n.t('titles.board'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    CarList: () => import('@/components/Board/CarList'),
    DriverList: () => import('@/components/Board/DriverList'),
    CarDriverList: () => import('@/components/Board/CarDriverList'),
    TransfersTable: () => import(/* webpackPrefetch: true */ '@/components/TransfersTable'),
    TransferDetails: () => import(/* webpackPrefetch: true */ '@/views/TransferDetails'),
  },
  data () {
    return {
      refreshLoading: true,
      pageLoading: false,
      isTransferListModalActive: false,
      isTransferModalActive: false,
      transferId: null,
      driverId: null,
      carId: null,
      board: {
        available_cars: [],
        available_drivers: [],
        planed_cars: [],
        on_road: [],
        complate: [],
      }
    }
  },
  computed: {
    ...mapState([
      'boardRefresh',
    ]),
    transferListWhere () {
      return {
        dateRange: [
          moment().toDate(), moment().toDate()
        ],
        status: [1]
      }
    }
  },
  watch: {
    boardRefresh (n) {
      if (n == true) {
        this.refresh()
      }
    }
  },
  created () {
  },
  mounted () {
    this.pageLoading = true
    setTimeout(() => {
      this.loadBoard();
    }, 700);
  },
  methods: {
    loadBoard () {
      this.$http
        .get('board')
        .then(({ data }) => {
          this.board = data;
          this.refreshLoading = false
          this.pageLoading = false
          this.$store.commit('boardRefresh', false)
        })
        .finally(() => {
          this.hideSpinner()
        });
    },
    showTransferList (driverId, carId) {
      this.driverId = driverId;
      this.carId = carId;
      this.isTransferListModalActive = true;
    },
    showTransfer (transferId) {
      this.transferId = transferId;
      this.isTransferModalActive = true;
    },
    refresh () {
      document.getElementById('refresh-button').blur();
      this.refreshLoading = true;
      this.loadBoard()
    },
    getCount (step) {
      if (this.pageLoading) {
        return '...';
      } else {
        return this.board[step].length
      }
    }
  },
}
</script>
